import enUS from './en-US';
import zhCN from './zh-CN';
export interface LangConf {
  locale: string;
  desc: string;
  [key: string]: string;
}

export interface LangMap {
  [key: string]: LangConf;
}

const langMap: LangMap = {
  'zh-CN': zhCN,
  'en-US': enUS,
};

export default langMap;
