import React, { FC, useState } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import BasicLayout from "./components/layout";
import { IntlProvider } from "react-intl";
import moment from "moment";
import langMap, { LangConf } from "./locales";
import { getLang, storeLang } from "./utils/lang";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak/config";
import "./app.less";

const getAntdLocale = (locale: string) => {
  const antdLocaleMap = {
    en: enUS,
    zh: zhCN,
  };
  return antdLocaleMap[locale as keyof typeof antdLocaleMap] || enUS;
};

const eventLogger = (event: unknown, error: unknown) => {
  console.debug("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: any) => {
  localStorage.setItem("token", tokens.token);
};

const App: FC = () => {
  const [lang, setLang] = useState(getLang());

  const changeLang = (value: string): void => {
    setLang(value);
    storeLang(value);
  };

  const messages: LangConf = langMap[lang] ? langMap[lang] : langMap["en-US"];
  moment.locale(messages.locale);
  const antdLocale = getAntdLocale(messages.locale);

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "check-sso" }}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <IntlProvider messages={messages} locale={messages.locale}>
        <ConfigProvider locale={antdLocale}>
          <BasicLayout changeLang={changeLang} />
        </ConfigProvider>
      </IntlProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
