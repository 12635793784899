export default {
  "evaTpl.list.createTime": "Create Time",
  "evaTpl.list.addEvaTpl": "Create Report Template",
  "evaTpl.list.tplName": "Name",
  "evaTpl.list.reportName": "Report Template Name",
  "evaTpl.list.tplTag": "Tag",
  "evaTpl.list.detail": "Report Template",
  "evaTpl.list.check": "View Template",
  "evaTpl.list.evaMethodName": "Evaluation Method Name",
  "evaTpl.detail.edit": "Edit",
  "evaTpl.edit.insertRowBelow": "Insert Below",
  "evaTpl.edit.insertRowLeft": "Insert Left",
  "evaTpl.edit.insertRowRight": "Insert Right",
  "evaTpl.edit.save": "Save",
  "evaTpl.edit.newTag": "New Table Tag",
};
