import { LangConf } from "./index";
import menu from "./zh-CN/menu";
import job from "./zh-CN/job";
import sdk from "./zh-CN/sdk";
import evaTpl from "./zh-CN/evaTpl";
import evaBase from "./zh-CN/evaBase";
import visual from "./zh-CN/visual";
const conf: LangConf = {
  locale: "zh",
  desc: "简体中文",
  hello: "你好",
  logout: "注销",
  "form.msg": "请输入正确内容",
  "form.select_msg": "请选择/添加感知类型",
  "form.add": "添加",
  "page.total": "共{total}项",
  ...menu,
  ...job,
  ...sdk,
  ...evaTpl,
  ...evaBase,
  ...visual,
};
export default conf;
