export default {
  "evaBase.list.cTime": "创建时间",
  "evaBase.list.name": "名称",
  "evaBase.list.status": "状态",
  "evaBase.list.resultTpl": "评测报告模板",
  "evaBase.list.createMethod": "新建评测方案",
  "evaBase.list.evaName": "评测方案名称",
  "evaBase.list.image": "评测镜像",
  "evaBase.list.caseTpl": "可视化模板",
  "evaBase.list.checkTpl": "查看报告模板",
  "evaBase.list.save": "确定",
  "evaBase.list.next": "下一步",
  "evaBase.list.deploy": "部署",
  "evaBase.list.deployed": "已部署",
  "evaBase.list.notDeployed": "未部署",
  "evaBase.list.createTpl": "创建模板",
  "evaBase.list.bindTpl": "添加报告模板",
  "evaBase.list.online": "在线",
  "evaBase.list.offline": "离线",
  "evaBase.list.custMethod": "新建评测方案",
  "evaBase.list.resTpl": "使用现有评测报告模板 / 新建评测报告模板",
  "evaBase.editor.tplDetail": "模板详情",
  "evaBase.editor.tplEditor": "模板编辑器",
  "evaBase.editor.createMethod": "创建方案",
  "evaBase.editor.deployImage": "部署镜像",
};
