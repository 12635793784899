// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "visual.list.cTime": "Create Time",
  "visual.list.fp": "FP",
  "visual.list.fn": "FN",
  "visual.list.tp": "TP",
  "visual.list.all": "ALL",
  "visual.list.title": "Frame Visualization",
  "visual.list.jobId": "Task ID",
  "visual.list.sdkVer": "Model SDK Version",
  "visual.list.evaName": "Evaluation Method Name",
  "visual.list.dataset": "Dataset",
  "visual.list.type": "Type",
  "visual.list.action": "Result Report",
  "visual.list.viewT": "View Report",
  "visual.frame.targetInfo": "Target Info",
  "visual.frame.bBoxType": "Bounding Box Type",
  "visual.frame.score": "Score",
  "visual.frame.bBoxSize": "Bounding Box Pixel Size",
  "visual.frame.prev": "Prev",
  "visual.frame.next": "Next",
};
