export default {
  'evaTpl.list.createTime': '创建时间',
  'evaTpl.list.addEvaTpl': '新建报告模板',
  'evaTpl.list.tplName': '模板名称',
  "evaTpl.list.reportName": "报告模板名称",
  'evaTpl.list.tplTag': '模板标签',
  'evaTpl.list.detail': '报告模板',
  'evaTpl.list.check': '查看详情',
  'evaTpl.list.evaMethodName': '测评方案名称',
  'evaTpl.detail.edit': '编辑',
  'evaTpl.edit.insertRowBelow': '向下添加行',
  'evaTpl.edit.insertRowLeft': '左侧添加列',
  'evaTpl.edit.insertRowRight': '右侧添加列',
  'evaTpl.edit.save': '保存',
  'evaTpl.edit.newTag': '新模板标签',
};
