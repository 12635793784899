export default {
  "job.list.createJob": "Create Task",
  "job.list.currentJob": "Current Task",
  "job.list.dataSet": "Dataset",
  "job.list.dataSetName": "Dataset Name",
  "job.list.gtName": "GT Name",
  "job.list.gt": "GT",
  "job.list.sdkArtifactory": "Model SDK",
  "job.list.sdkid": "Model SDK ID",
  "job.list.evaName": "Evaluation Method",
  "job.list.evaMethod": "Evaluation Method Name",
  "job.list.type": "Type",
  "job.list.jobType": "Type",
  "job.list.status": "Status",
  "job.list.process": "Process",
  "job.list.createTime": "Create Time",
  "job.list.updateTime": "Update Time",
  "job.list.result": "Report",
  "job.list.checkResult": "View Report",
  "job.list.analyzeFrames": "Visualize Frame",
  "job.list.submit": "Submit",
  "job.list.sdklist": "Use existing ones / Create a new one",
  "job.list.baselist": "Use existing ones / Create a new one",
  "job.list.jobId": "Job Id",
  "job.list.click": "Click here",
  "job.list.noTpl":
    "This evaluation result has no template , if you want to bind one, ",
  "job.list.status.1": "Processing",
  "job.list.status.2": "Failed: Task Unassigned",
  "job.list.status.3": "Processing",
  "job.list.status.4": "Failed: Perception Error",
  "job.list.status.5": "Processing",
  "job.list.status.6": "Processing",
  "job.list.status.7": "Failed: DT Error",
  "job.list.status.8": "Processing",
  "job.list.status.9": "Failed: Trigger Error",
  "job.list.status.10": "Processing",
  "job.list.status.11": "Failed: Evaluation Error",
  "job.list.status.12": "Done",

};
