// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "header.dxp": "CLA｜Console",
  "header.mil": "CLA｜Model Evaluation",

  "menu.error": "NotFound",
  "menu.task": "Jobs",
  "menu.task.list": "Job List",
  "menu.task.add": "Add Job",
  "menu.dashboard": "Dashboard",
  "menu.joblist": "Task Center",
  "menu.jobdetail": "Task Center",
  "menu.jobdetailTail": "Report",
  "menu.eva.baselist": "Evaluation Method",
  "menu.eva.baseDetail": "Evaluation Method",
  "menu.eva.sdklist": "Model SDK",
  "menu.evatpl": "Evaluation Template",
  "menu.evatpl.list": "Result Template",
  "menu.evatpl.detail": "Template Detail",
  "menu.evatpl.editor": "Template Editor",
  "menu.evatpl.creator": "Template Editor",

  "modal.errorDetail": "Error Content",
  "modal.detailProgress": "Progress Content",
  "modal.item": "Item",
  "modal.items": "Items",
  "modal.searchDataCollection": "Search Data Collection",
  "menu.eva": "Preparation Area",
  "menu.eva.resultlist": "Report Template",
  "menu.eva.tpldetail": "Report Template",
  "menu.eva.tpldetailTail": "Report Template Detail",
  "menu.eva.tpleditor": "Report Template",
  "menu.eva.tplcreator": "Report Template",
  "menu.eva.tpleditorTail": "Template Editor",
  "menu.caselist": "Result Report Center",
  "menu.casedetail": "Task Center",
  "menu.casedetailTail": "Frame Visualization",
  "menu.caseframe": "Task Center",
  "menu.caseframeTail": "Frame Visualization",

  "menu.taskmanagement": "Task",
  "menu.taskmanagement.datacollection": "Data Collection",
  "menu.taskmanagement.ingestion_tasks": "Ingestion Tasks",
  "menu.taskmanagement.process_tasks": "Processing Tasks",
  "menu.taskmanagement.systemtask": "Data",
  "menu.processormanagement": "Processor",
  "menu.processormanagement.processors": "Processors",
  "menu.datamanagement": "Data",
  "menu.datamanagement.views": "Data Views",
  "menu.datamanagement.datasets": "Datasets",
  "menu.datamanagement.dxp": "dxp",
  "menu.datamanagement.tsr_filter": "tsr_filter",
  "menu.datamanagement.Test": "Test",
  "menu.datamanagement.baseai_test": "baseai_test",
  "menu.datamanagement.baseai-test-0": "baseai-test-0",
  "menu.datamanagement.gen3_tsr_test": "gen3_tsr_test",
  "menu.datamanagement.traffic_sign_ingest_fvg3": "traffic_sign_ingest_fvg3",
  "menu.datamanagement.demo": "demo",
  "menu.datamanagement.string": "string",
  "menu.datamanagement.traffic_Sign": "traffic_Sign",
  "menu.datamanagement.updated_type": "updated_type",
  "menu.datamanagement.bosch-test": "bosch-test",
  "menu.datamanagement.Clip4a": "Clip4a",
  "menu.datamanagement.vehicle_filter": "vehicle_filter",
  "menu.datamanagement.sfda": "sfda",
  "menu.datacategory": "Data",
  "menu.dataview": "Data View",
  "menu.data": "Data Detail",
  "menu.dataset": "Dataset",
  "menu.datasetPreview": "Dataset Preview",

  "modal.common.ok": "Submit",
  "modal.common.cancel": "Cancel",
  "transfer.common.item": "Item",
  "form.common.required": "Required",
  "form.common.nameRule":
    "Required. A-Z, a-z, 0-9, - , _ , only. Do not start with numbers.",

  "search.common.reset": "Reset",
  "search.common.confirm": "Search",
  
  "search.common.ok": "Search",

  "button.signOut": "Sign Out",

  "pagination.common.show_total_prefix": "15 records per page, ",
  "pagination.common.show_total_suffix": " records in total",

  "table.taskmanagement.datacollection.dataset_name": "Input",
  "table.taskmanagement.datacollection.collect_type": "Collect Type",
  "table.taskmanagement.datacollection.trigger_type": "Trigger Type",
  "table.taskmanagement.datacollection.disk_status": "Disk Status",
  "table.taskmanagement.datacollection.progress": "Progress",
  "table.taskmanagement.datacollection.status": "Status",

  "table.taskmanagement.disktask.recycle_task": "Cloud Ingestion Task",
  "table.taskmanagement.disktask.current_task": "Current Task",
  "table.taskmanagement.disktask.history_task": "History Task",
  "table.taskmanagement.disktask.serial": "serial",
  "table.taskmanagement.disktask.created_at": "Create Time",
  "table.taskmanagement.disktask.updated_at": "Update Time",
  "table.taskmanagement.disktask.region": "Region",
  "table.taskmanagement.disktask.type": "Type",
  "table.taskmanagement.disktask.hostname": "Hostname",
  "table.taskmanagement.disktask.slot": "Slot",
  "table.taskmanagement.disktask.disk_status": "Disk Status",
  "table.taskmanagement.disktask.task_status": "Task Status",
  "table.taskmanagement.disktask.action": "Operation",
  "table.taskmanagement.disktask.upload": "Upload",
  "table.taskmanagement.disktask.eject": "Eject",
  "table.taskmanagement.disktask.recycleNum": "Gathered Data Size",
  "table.taskmanagement.disktask.nextRecycleTime": "Next Ingestion Time",

  "table.taskmanagement.process_tasks.create_new_task": "Create Task",
  "table.taskmanagement.process_tasks.dataset_name": "Input Data View",
  "table.taskmanagement.process_tasks.dst_dataset_name": "Output Data View",
  "table.taskmanagement.process_tasks.processor_name": "Processor Name",
  "table.taskmanagement.process_tasks.created_at": "Create Time",
  "table.taskmanagement.process_tasks.updated_at": "Update Time",
  "table.taskmanagement.process_tasks.trigger_type": "Trigger Type",
  "table.taskmanagement.process_tasks.progress": "Progress",
  "table.taskmanagement.process_tasks.status": "Status",
  "table.taskmanagement.processtask.created_at.hint":
    "Only showing tasks created within 7 days by default. Specify time range in Create Time column to see tasks of 7 days before.",

  "table.processormanagement.processors.create_new_processor":
    "Create Processor",
  "table.processormanagement.processors.name": "Name",
  "table.processormanagement.processors.image": "Docker Image",
  "table.processormanagement.processors.created_at": "Create Time",
  "table.processormanagement.processors.collect_type": "Collect Type",
  "table.processormanagement.processors.storage_src_bucket": "Source Storage",
  "table.processormanagement.processors.storage_dst_bucket":
    "Destination Storage",
  "table.processormanagement.processors.trigger_type": "Trigger Type",
  "table.processormanagement.processors.gpu_num": "GPU Number",
  "table.processormanagement.processors.status": "Status",
  "table.processormanagement.processors.app_execution_mode": "Execution Mode",

  "table.datamanagement.dataviews.name": "Data View Name",
  "table.datamanagement.dataviews.collect_type": "Collect Type",
  "table.datamanagement.dataviews.dataview_type": "Data View Type",
  "table.datamanagement.dataviews.dataset_count": "Dataset Amount",
  "table.datamanagement.dataviews.data_count": "Data Amount",
  "table.datamanagement.dataviews.created_at": "Create Time",
  "table.datamanagement.dataviews.action": "Operation",

  "button.datamanagement.dataviews.create": "Create Data View",
  "lableTask.Table.createTime": "Create Time",
  "form.datamanagement.dataviews.dataview_name":
    "User-defined. Do not use the same name as an existing data view.",
  "form.datamanagement.dataviews.dataview_type": "Select data view type",
  "form.datamanagement.dataviews.select_dataset": "Select Dataset",
  "form.datamanagement.dataviews.search_dataset": "Search Dataset",

  "table.datamanagement.datasets.name": "Dataset Name",
  "table.datamanagement.datasets.collect_type": "Collect Type",
  "table.datamanagement.datasets.dataset_type": "Dataset Type",
  "table.datamanagement.datasets.data_count": "Data Amount",
  "table.datamanagement.datasets.created_at": "Create Time",
  "table.datamanagement.datasets.gt_version_count": "GT Version Amount",
  "table.datamanagement.datasets.action": "Operation",

  "button.datamanagement.datasets.create": "Create Dataset",
  "button.datamanagement.datasets.edit_tag": "Edit Tag",
  "button.datamanagement.datasets.send_to_label": "Send To Label",
  "button.datamanagement.datasets.download": "Download",
  "button.datamanagement.datasets.selected": "Selected",
  "button.datamanagement.datasets.unselected": "Click to select",
  "button.datamanagement.datasets.copy_selected": "Copy Selected",
  "button.datamanagement.datasets.copy_unselected": "Copy Unselected",

  "form.datamanagement.datasets.dataset_name":
    "User-defined. Do not use the same name as an existing dataset.",
  "form.datamanagement.datasets.dataset_type": "Select dataset type",
  "form.datamanagement.datasets.md5_list": "MD5 List",
  "form.datamanagement.datasets.md5_list_placeholder": `The list should be entered like ["75f4d25313db22c1d77efa87f63b3c54", "75f4d25313db22c1d77efa87f63b3c55"] \nUsers can also use comma to separate md5.`,
  "form.datamanagement.datasets.invalid_md5":
    "Creation failed, please fill in a valid MD5 list",

  "form.datamanagement.datasets.send_to_label": "Send to Label",
  "form.datamanagement.datasets.select_label_task": "Select Label-task",
  "form.datamanagement.datasets.select_label_task_info":
    "Please wait a few minutes after submission, you will see tags on the datasets when label task has successfully sent. ",

  "modal.datamanagement.datasets.edit_tag": `The list should be entered like ["tag1", "tag2"]\nUsers can also use comma to separate tags.`,
  "search.datamanagement.data.md5_search": "Search MD5",

  "form.processor.createProcessor": "Create Processor",
  "form.processor.processorName": "Processor Name",
  "form.processor.image": "Docker Image",
  "form.processor.appCommand": "App Command",
  "form.processor.appExecutionMode": "App Execution Mode",
  "form.processor.collectType": "Collect Type",
  "form.processor.triggerType": "Trigger Type",
  "form.processor.gpuNum": "GPU Number",
  "form.processor.processorNameRule":
    "Required. A-Z, a-z, 0-9, - only. Do not start with numbers.",
  "form.processor.processorLengthRule": "Please limit it to 1-128 characters.",
  "form.processor.processorImageRule":
    "Required. The image file does not conform to the requests. Do not use Chinese characters.",
  "form.processor.require": "Required",
  "form.processor.submitSuccess": "Submit Processor Successfully!",

  "form.task.selectTask": "Select Data Views",
  "form.task.selectProcessor": "Select Processor",
  "form.task.processorDetails": "Processor Details",
  "form.task.checkDetails": "More Details",
  "form.task.caution":
    " Once you submit, the platform will immediately start executing the task and it’s not allowed to cancel.  Please make sure you select the correct information before submission!",

  "form.task.dataCollectionRule": "Require to select at least one item",

  "modal.processorDetail.name": "Processor Name",
  "modal.processorDetail.image": "Docker Image",
  "modal.processorDetail.storage_src_bucket": "Source Storage",
  "modal.processorDetail.storage_dst_bucket": "Destination Storage",

  "error.dip": "Ingestion bag Pre-processing",
  "error.dpp": "Bag Data Processing",

  "progressBar.msg": "Progress: ",
  "status.emptyErrorMsg": "Please contact Admin",
  "status.procgoerror":
    "Data Management Platform or Object Storage Service connection error",
  "status.apperror": "Processor internal error",
  "status.errorDetails": "Error Content",
  "status.waiting": "Waiting",
  "status.running": "Running",
  "status.failed": "Failed",
  "status.succeeded": "Done",

  "jobtype.manual": "Manual",
  "jobtype.system": "System",
  "jobtype.mviz": "Mviz",

  "processorStatus.enable": "Enabled",
  "processorStatus.disabled": "Disabled",

  "diskStatus.mounted": "Unmount Prohibited",
  "diskStatus.inserted": "Unmount Allowed	",
  "diskStatus.unavailable": "Unmounted",

  "processorType.system": "Automatic",
  "processorType.manual": "Manual",
  "processorType.mviz": "Mviz",

  "table.datamanagement.databatches.name": "Data View Name",
  "table.datamanagement.databatches.collect_type": "Collect Type",
  "table.datamanagement.databatches.databatch_type": "Data View Type",
  "table.datamanagement.databatches.dataset_count": "Dataset Amount",
  "table.datamanagement.databatches.data_count": "Data Amount",
  "table.datamanagement.databatches.created_at": "Create Time",
  "table.datamanagement.databatches.action": "Operation",

  "button.datamanagement.databatches.create": "Create Data View",

  "form.datamanagement.databatches.databatch_name":
    "User-defined. Do not use the same name as an existing data view.",
  "form.datamanagement.databatches.databatch_type": "Select data view type",
  "form.datamanagement.databatches.select_dataset": "Select Dataset",
  "form.datamanagement.databatches.search_dataset": "Search Dataset",

  "table.datamanagement.data_view": "Data View",
  "table.datamanagement.data_set": "Dataset",
  "table.datamanagement.data": "Data Search",
  "table.datamanagement.column.name": "Name",
  "table.datamanagement.column.create_time": "Create Time",
  "table.datamanagement.column.update_time": "Update Time",
  "table.datamanagement.column.collect_time": "Collect Time",
  "table.datamanagement.column.sync_progress": "Sync Progress",
  "table.datamanagement.column.task_status": "Task Status",
  "table.datamanagement.column.resync": "Resync",
  "table.datamanagement.column.in_the_line": "in the line",
  "table.datamanagement.column.preparing": "preparing",
  "table.datamanagement.column.cancel": "cancel",
  "table.datamanagement.column.synchronizing": "synchronizing",
  "table.datamanagement.column.completed": "completed",
  "table.datamanagement.column.failed": "failed",
  "table.datamanagement.column.not_support": "not_support",
  "table.datamanagement.column.owner": "User",
  "table.datamanagement.column.dataset_count": "Dataset Amount",
  "table.datamanagement.column.data_count": "Data Amount",
  "table.datamanagement.column.gt_version": "GT Version",
  "table.datamanagement.column.size": "Size",
  "table.datamanagement.column.tag": "Tag",
  "table.datamanagement.column.op": "Operation",
  "table.datamanagement.op.cli": "Copy Command",
  "table.datamanagement.op.edit_tag": "Edit Tag",
  "table.datamanagement.op.detail": "Detail",
  "table.datamanagement.op.mviz": "Mviz Play",
  "table.datamanagement.op.download": "Download",
  "table.datamanagement.modal.tag.title.prefix": "Edit",
  "table.datamanagement.modal.tag.title.subfix": "Tag",
  "table.datamanagement.modal.tag.prefix":
    "Enter Tag ( A-Z a-z 0-9 - _ only. )",
  "table.datamanagement.modal.tag.subfix":
    "Irreversible, please confirm before submitting.",
  "table.datamanagement.modal.tag.message.suc": "OK",
  "table.datamanagement.modal.tag.message.failed": "Failed",
  "table.datamanagement.search": "Search",
  "table.datamanagement.search.clear": "Reset",
  "table.datamanagement.search.title": "Data Search",
  "table.datamanagement.search.name": "Name",
  "table.datamanagement.search.data_space": "Data Space",
  "table.datamanagement.search.md5": "MD5",
  "table.datamanagement.search.carname": "Plate Number",
  "table.datamanagement.search.tag": "Tag",
  "table.datamanagement.search.cnname": "Chinese Name",
  "table.datamanagement.search.enname": "English Name",
  "table.datamanagement.search.collect_time": "Collect Time ",
  "table.datamanagement.search.create_time": "Create Time ",
  "table.datamanagement.search.timeStart": "Create Time Start",
  "table.datamanagement.search.timeEnd": "Create Time End",
  "table.datamanagement.search.serial": "Hard Disk Serial Number",
  "table.datamanagement.search.collect_type": "Collect Type",
  "table.datamanagement.search.owner": "User",
  "table.datamanagement.search.rd_type": "CLA Data Collection Trigger Type",
  "table.datamanagement.search.rd_trigger": "CLA Data Collection Trigger Name",
  "table.datamanagement.search.trigger_serial": "Data Collection Device ID",
  "table.datamanagement.search.a4": "A4 Filter Name",
  "table.datamanagement.search.processor": "DXP Processor Name",
  "table.datamanagement.search.process_time": "DXP Processing Time ",
  "table.datamanagement.search.time.start": "Start",
  "table.datamanagement.search.time.end": "End",
  "table.datamanagement.search.tag.placeholder": "Exact term query",
  "table.datamanagement.search.input.placeholder":
    "Fuzzy term query, * supported",
  "table.datamanagement.search.input.placeholder2": "Exact term query",
  "table.datamanagement.search.show": "Expand",
  "table.datamanagement.search.hide": "Collapse",
  "table.datamanagement.search.scope.schema": "Current Data Space",
  "table.datamanagement.search.scope.global": "All Data Space",
  "table.datamanagement.create.view": "Create Data View",
  "table.datamanagement.create.view.name": "Name",
  "table.datamanagement.create.view.placeholder":
    "Globally unique, length limited: X characters, A-Z a-z 0-9 - _ only. ",
  "table.datamanagement.create.view.datasetBtn": "Add Dataset",
  "table.datamanagement.create.view.dataset":
    "Enter datasets name, one per line",
  "table.datamanagement.create.dataset": "Create Dataset",
  "table.datamanagement.create.dataset.placeholder":
    "Globally unique, length limited: X characters, A-Z a-z 0-9 - _ only. ",
  "table.datamanagement.create.dataset.name": "Name",
  "table.datamanagement.create.dataset.tag":
    "Length limited: A-Z a-z 0-9 - _ only. ",
  "table.datamanagement.create.dataset.md5": "Enter MD5, one per line",
  "table.datamanagement.custom_tag.add": "+ Common Tag",
  "table.datamanagement.custom_tag.title": "Add Common Tag",
  "table.datamanagement.custom_tag.error.toomany":
    "Failed: The number of tags exceeded 15",
  "table.datamanagement.custom_tag.error.auth":
    "Failed: only admin can add common tag",
  "table.datamanagement.tip.mdi.anchor": "Download the DMP CLI Tool (MDI Tool)",
  "table.datamanagement.tip.mdi.tip": "MDI Tool Manual Book",

  "dataDetail.desc.name": "Name",
  "dataDetail.desc.create_time": "Create Time",
  "dataDetail.desc.owner": "User",
  "dataDetail.desc.tag": "Tag",
  "dataDetail.desc.gt": "GT Version",
  "dataDetail.desc.collect_time": "Collect Time",
  "dataDetail.desc.category": "Data Space",
  "dataDetail.desc.dataset": "Dataset",
  "dataDetail.desc.dataset_amount": "Dataset Amount",
  "dataDetail.desc.size": "Size",
  "dataDetail.data.relative": "	Related Data",
  "dataDetail.data.meta": "Metadata",
  "dataDetail.data.gt": "Ground Truth",
  "dataDetail.desc.amount": "Data Amount",
  "dataDetail.download": "Download",
  "dataDetail.mviz": "Mviz Play",
  "dataDetail.prev": "Prev",
  "dataDetail.next": "Next",
  "dataDetail.tip.copy":
    "MDI tool - download command has been copied to the clipboard.",
  "dataDetail.view": "Data View Details",
  "dataDetail.dataset": "Dataset Details",
  "dataDetail.data": "Data Details",

  "pagination.1": " ",
  "pagination.2": " records per page, ",
  "pagination.3": " records in total",
  ProcessorSet: "Processor Set",
  Description: "Description",
  "lableTask.TabPane.state": "State",
  "table.taskmanagement.processtask.systemCreate": "Auto-assigned Task",
  "table.taskmanagement.processtask.manualCreate": "Manually Created Task",
  "table.taskmanagement.processtask.mvizCreate": "Mviz Task",
  "table.taskmanagement.processtask.create_new_task": "Create Task",
  "table.taskmanagement.processtask.CreatedWorkflowTask":
    "Create Workflow Task",
  "table.processormanagement.templates.operate": "Operate",
  "table.processormanagement.templates.delete": "Delete",
  "form.processor.gpu": "GPU Resource",
  "table.processormanagement.processors.create_new_workflow":
    "Create Workflow Config",
  "A-Za-z0-9only": "A-Z a-z 0-9 -",
  "form.processor.placeholder.name": "A-Z a-z 0-9 -",
  PleaseInputWorkflowConfigName: "Please input the workflow config name",
  PleaseInputWorkflowConfigName0:
    "Please input the workflow config name, only A-Z a-z 0-9 -",
  "table.taskmanagement.processtask.dataset_name": "Input Data View",
  "table.taskmanagement.processtask.collect_type": "Proc Type",
  "table.taskmanagement.processtask.dst_dataset_name": "Output Data View",
  "table.taskmanagement.processtask.processor_name": "Processor Name",
  "table.taskmanagement.processtask.creator": "Creator",
  "table.taskmanagement.processtask.created_at": "Create Time",
  "table.taskmanagement.processtask.updated_at": "Update Time",
  "table.taskmanagement.processtask.trigger_type": "Trigger Type",
  "table.taskmanagement.processtask.progress": "Progress",
  "table.taskmanagement.processtask.status": "Status",
  "table.taskmanagement.processtask.action": "Action",
  "table.taskmanagement.processtask.download": "Download Log",
  "diskTask.taskStatusTitle": "Status Details",
  "table.taskmanagement.processtask.submitSuccess":
    "The task is submitted successfully！",
  "table.taskmanagement.processtask.submitCheck":
    "The task can not be  modified or canceled  after submission. Confirm to submit?",
  "table.taskmanagement.processtask.submitFailed":
    "Failed to submit. Please contact Admin.",
  "table.processormanagement.processors.workflowConfigName":
    "Workflow Config Name",
  "table.taskmanagement.process_tasks.normaltask": "Normal Tasks",
  "table.taskmanagement.process_tasks.workflowtasks": "Workflow Tasks",
  "table.taskmanagement.process_tasks.processorlist": "Processor List",
  "table.taskmanagement.processor.id": "Processor Id",
  "table.taskmanagement.processor.order": "Processor Order",
  "table.taskmanagement.process_tasks.workflow_config_list":
    "Workflow Config List",
  "table.processormanagement.processors.workflowConfigDescription":
    "Workflow Config Description",
  CreatedWorkflowTask: "Create Workflow Task",
  CreatedWorkflowTaskSearchPlaceholder:
    "Input prefix of Data View name to search",
  SelectWorkflowConfig: "Select Workflow Config",
  AutoAssignedWorkflowTask: "Auto-assigned Workflow Task",
  ManuallyCreatedWorkflowTaskask: "Manually Create Workflow Task",
  submitConfirm:
    "The task can not be modified or canceled after submission. Confirm to submit?",
  SearchWorkflowConfig: "Search Workflow Config",
  WorkflowConfigInfo: "Workflow Config Info",
  "Sub-Progress": "Sub-Progress",
  "Sub-Status": "Sub-Status",
  SucceedToAddProcessor: "Succeed to add processor",
  AddedProcessorDragToAdjustTheOrder:
    "Added processor, drag to adjust the order.",
  ThisProcessorHasBeenAdded: "This processor has been added",
  PleaseSelectProcessor: "Please select a processor",
  Maximum1000Characters: "Maximum 1000 characters",
  PleaseSelectWorkflowConfiguration: "Please select workflow configuration",
  PleaseSelectDataView: "Please select a data view",
  AreYouSureToSubmitWorkflow:
    "The workflow config can not be modified or cancelled after submission. Confirm to submit?",
  AreYouSureToSubmit:
    "You cannot cancel or modify the task after it is submitted. Are you sure you want to submit it?",
  TaskProgressPrompt:
    "The first processor name is displayed in the table, and hovering over the name displays information for all processors in the workflow configuration.",
};
