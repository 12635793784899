export default {
  "visual.list.cTime": "Create Time",
  "visual.list.fp": "误检",
  "visual.list.fn": "漏检",
  "visual.list.tp": "检出",
  "visual.list.all": "全部",
  "visual.list.title": "帧数据可视化",
  "visual.list.jobId": "任务ID",
  "visual.list.sdkVer": "模型版本",
  "visual.list.evaName": "关联的评测方案名称",
  "visual.list.dataset": "评测数据集",
  "visual.list.type": "类型",
  "visual.list.action": "评测结果报告",
  "visual.list.viewT": "查看结果",
  "visual.frame.targetInfo": "目标类别",
  "visual.frame.bBoxType": "Bounding Box 类型",
  "visual.frame.score": "Score阈值",
  "visual.frame.bBoxSize": "Bounding Box 像素面积",
  "visual.frame.prev": "上一帧",
  "visual.frame.next": "下一帧",
};
