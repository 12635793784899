// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "header.dxp": "CLA｜控制台",
  "header.mil": "CLA｜模型评测平台",

  "menu.error": "出错了",
  "menu.task": "任务管理",
  "menu.task.list": "任务列表",
  "menu.task.add": "新增任务",
  "menu.dashboard": "控制台",
  "menu.joblist": "评测任务中心",
  "menu.jobdetail": "评测任务中心",
  "menu.jobdetailTail": "报告",
  "menu.eva.baselist": "评测方案列表",
  "menu.eva.baseDetail": "评测方案列表",
  "menu.eva.sdklist": "模型版本列表",
  "menu.evatpl": "评测报告模板",
  "menu.evatpl.list": "模板列表",
  "menu.evatpl.detail": "模板详情",
  "menu.evatpl.editor": "模板编辑器",

  "menu.eva": "评测方案准备区",
  "menu.eva.resultlist": "评测报告模板列表",
  "menu.eva.tpldetail": "评测报告模板列表",
  "menu.eva.tpldetailTail": "报告模板详情",
  "menu.eva.tpleditor": "模板编辑器",
  "menu.eva.tplcreator": "评测报告模板",
  "menu.eva.tpleditorTail": "模板编辑器",
  "menu.caselist": "评测结果报告中心",
  "menu.casedetail": "评测任务中心",
  "menu.casedetailTail": "帧数据可视化",
  "menu.caseframe": "评测任务中心",
  "menu.caseframeTail": "帧数据可视化",

  "menu.dataset": "数据集详情",
  "menu.datasetPreview": "数据集预览",
  "menu.data": "数据详情",
  "menu.dataview": "视图详情",
  "menu.datacategory": "数据空间",
  "menu.taskmanagement": "任务管理",
  "menu.taskmanagement.datacollection": "数据批次任务",
  "menu.taskmanagement.ingestion_tasks": "回收任务",
  "menu.taskmanagement.process_tasks": "处理任务",
  "menu.taskmanagement.systemtask": "系统任务",
  "menu.processormanagement": "处理器管理",
  "menu.processormanagement.processors": "处理器列表",
  "menu.datamanagement": "数据管理",
  "menu.datamanagement.views": "数据集合列表",
  "menu.datamanagement.datasets": "数据集列表",
  "menu.datamanagement.data": "数据列表",
  "menu.datamanagement.dxp": "dxp",
  "menu.datamanagement.tsr_filter": "tsr_filter",
  "menu.datamanagement.Test": "Test",
  "menu.datamanagement.baseai_test": "baseai_test",
  "menu.datamanagement.baseai-test-0": "baseai-test-0",
  "menu.datamanagement.gen3_tsr_test": "gen3_tsr_test",
  "menu.datamanagement.traffic_sign_ingest_fvg3": "traffic_sign_ingest_fvg3",
  "menu.datamanagement.demo": "demo",
  "menu.datamanagement.string": "string",
  "menu.datamanagement.traffic_Sign": "traffic_Sign",
  "menu.datamanagement.updated_type": "updated_type",
  "menu.datamanagement.bosch-test": "bosch-test",
  "menu.datamanagement.Clip4a": "Clip4a",
  "menu.datamanagement.vehicle_filter": "vehicle_filter",
  "menu.datamanagement.sfda": "sfda",

  "modal.common.ok": "提交",
  "modal.common.cancel": "取消",
  "transfer.common.item": "项",
  "form.common.required": "必填",
  "form.common.nameRule":
    "必填，名称中只允许含有英文字母、_、-以及数字，不能以数字开头",

  "search.common.reset": "重置",
  "search.common.ok": "搜索",
  "search.common.confirm": "搜索",

  "button.signOut": "注销",

  "pagination.common.show_total_prefix": "每页15条，共",
  "pagination.common.show_total_suffix": "条数据",

  "table.taskmanagement.datacollection.dataset_name": "数据批次名称",
  "table.taskmanagement.datacollection.collect_type": "采集类型",
  "table.taskmanagement.datacollection.trigger_type": "创建类型",
  "table.taskmanagement.datacollection.disk_status": "硬盘状态",
  "table.taskmanagement.datacollection.progress": "最新任务进度",
  "table.taskmanagement.datacollection.status": "状态",

  "table.taskmanagement.disktask.recycle_task": "云回收任务",
  "table.taskmanagement.disktask.current_task": "当前任务",
  "table.taskmanagement.disktask.history_task": "历史任务",
  "table.taskmanagement.disktask.serial": "硬盘序列号",
  "table.taskmanagement.disktask.created_at": "创建时间",
  "table.taskmanagement.disktask.updated_at": "更新时间",
  "table.taskmanagement.disktask.region": "区域",
  "table.taskmanagement.disktask.type": "类型",
  "table.taskmanagement.disktask.hostname": "主机",
  "table.taskmanagement.disktask.slot": "槽位",
  "table.taskmanagement.disktask.disk_status": "硬盘状态",
  "table.taskmanagement.disktask.task_status": "任务状态",
  "table.taskmanagement.disktask.action": "操作",
  "table.taskmanagement.disktask.upload": "上传",
  "table.taskmanagement.disktask.eject": "弹出",
  "table.taskmanagement.disktask.recycleNum": "待回收数据量",
  "table.taskmanagement.disktask.nextRecycleTime": "下次回收时间",
  "diskTask.taskStatusTitle": "状态详情",

  "table.taskmanagement.process_tasks.create_new_task": "新建任务",
  "table.taskmanagement.process_tasks.dataset_name": "数据批次名称",
  "table.taskmanagement.process_tasks.dst_dataset_name": "生成数据批次",
  "table.taskmanagement.process_tasks.processor_name": "处理器名称",
  "table.taskmanagement.process_tasks.created_at": "创建时间",
  "table.taskmanagement.process_tasks.updated_at": "更新时间",
  "table.taskmanagement.process_tasks.trigger_type": "创建类型",
  "table.taskmanagement.process_tasks.progress": "任务进度",
  "table.taskmanagement.process_tasks.status": "状态",
  "table.taskmanagement.process_tasks.normaltask": "普通任务",
  "table.taskmanagement.process_tasks.workflowtasks": "工作流任务",
  "table.taskmanagement.process_tasks.processorlist": "处理器列表",
  "table.taskmanagement.process_tasks.workflow_config_list": "工作流配置列表",
  "table.taskmanagement.processtask.created_at.hint":
    "默认仅展示7天以内的任务，若需查询历史任务请指定时间段",

  "table.processormanagement.processors.name": "处理器名称",
  "table.processormanagement.processors.created_at": "创建时间",
  "table.processormanagement.processors.collect_type": "类型",
  "table.processormanagement.processors.storage_src_bucket": "数据源存储位置",
  "table.processormanagement.processors.storage_dst_bucket": "数据目标存储位置",
  "table.processormanagement.processors.trigger_type": "处理器属性",
  "table.processormanagement.processors.status": "状态",
  "table.processormanagement.processors.gpu_num": "GPU数量",
  "table.processormanagement.processors.app_execution_mode": "运行模式",
  "table.processormanagement.processors.workflowConfigName": "工作流配置名称",
  "table.processormanagement.processors.systemCreate": "自动处理器",
  "table.processormanagement.processors.manualCreate": "手动处理器",
  "table.processormanagement.processors.mvizCreate": "Mviz 处理器",
  "table.processormanagement.processors.autoWorkflowCreate": "自动工作流配置",
  "table.processormanagement.processors.manualWorkflowCreate": "手动工作流配置",
  "table.processormanagement.processors.create_new_processor": "创建处理器",
  "table.processormanagement.processors.create_new_workflow": "创建工作流配置",
  "table.processormanagement.processors.workflowConfigDescription":
    "工作流配置描述",
  "table.taskmanagement.processor.order": "处理顺序",
  "table.taskmanagement.processor.id": "处理器id",
  "A-Za-z0-9only": "A-Z a-z 0-9 -",
  "form.processor.placeholder.name": "A-Z a-z 0-9 -",
  PleaseInputWorkflowConfigName: "请填写名称",
  PleaseInputWorkflowConfigName0: "填写名称，支持 A-Z a-z 0-9 -",
  "table.processormanagement.processors.creator": "创建者",
  "table.processormanagement.processors.image": "镜像文件",
  "table.processormanagement.processors.details": "详情",
  "table.processormanagement.processors.checkDetails": "查看",
  "table.processormanagement.processors.submitSuccess": "处理器提交成功!",
  "table.processormanagement.processors.submitCheck":
    "处理器提交后无法取消或修改，是否确认提交？",
  "table.processormanagement.processors.submitFailed":
    "处理器提交失败,请联系管理员",
  "form.processor.gpu": "GPU资源",
  "table.processormanagement.templates.operate": "操作",
  "table.datamanagement.dataviews.name": "数据集合名称",
  "table.datamanagement.dataviews.collect_type": "类型",
  "table.datamanagement.dataviews.dataview_type": "数据集合类型",
  "table.datamanagement.dataviews.dataset_count": "数据集个数",
  "table.datamanagement.dataviews.data_count": "数据个数",
  "table.datamanagement.dataviews.created_at": "创建时间",
  "table.datamanagement.dataviews.action": "操作",
  "lableTask.Table.createTime": "创建时间",

  "button.datamanagement.dataviews.create": "创建数据集合",
  CreatedWorkflowTask: "创建工作流任务",
  SelectWorkflowConfig: "选择工作流配置",
  "form.datamanagement.dataviews.dataview_name":
    "自定义名称，不能与已有数据集合同名",
  "form.datamanagement.dataviews.dataview_type": "选择数据集合类型",
  "form.datamanagement.dataviews.select_dataset": "选择数据集",
  "form.datamanagement.dataviews.search_dataset": "搜索数据集",

  // "table.datamanagement.datasets.name": "数据集名称",
  // "table.datamanagement.datasets.collect_type": "类型",
  // "table.datamanagement.datasets.dataset_type": "数据集类型",
  // "table.datamanagement.datasets.data_count": "数据个数",
  // "table.datamanagement.datasets.created_at": "创建时间",
  // "table.datamanagement.datasets.gt_version_count": "真值版本数",
  // "table.datamanagement.datasets.action": "操作",

  "button.datamanagement.datasets.create": "创建数据集",
  "button.datamanagement.datasets.edit_tag": "编辑标签",
  "button.datamanagement.datasets.send_to_label": "送标",
  "button.datamanagement.datasets.download": "下载",
  "button.datamanagement.datasets.selected": "已选",
  "button.datamanagement.datasets.unselected": "选择数据",
  "button.datamanagement.datasets.copy_selected": "复制已选",
  "button.datamanagement.datasets.copy_unselected": "复制未选",

  "form.datamanagement.datasets.dataset_name":
    "自定义名称，不能与已有数据集同名",
  "form.datamanagement.datasets.dataset_type": "选择数据集类型",
  "form.datamanagement.datasets.md5_list": "数据MD5列表",
  "form.datamanagement.datasets.md5_list_placeholder": `形如["75f4d25313db22c1d77efa87f63b3c54", "75f4d25313db22c1d77efa87f63b3c55"]的列表\n也可直接使用逗号分隔md5`,
  "form.datamanagement.datasets.invalid_md5": "创建失败，请填写合法的MD5列表",

  "form.datamanagement.datasets.send_to_label": "标注数据",
  "form.datamanagement.datasets.select_label_task": "选择标注任务",
  "form.datamanagement.datasets.select_label_task_info":
    "成功提交后，标注任务标签讲稍后出现在相应的数据集旁边",

  "modal.datamanagement.datasets.edit_tag": `形如["tag1", "tag2"]的列表, 也可直接使用逗号分隔tag`,
  "search.datamanagement.data.md5_search": "MD5 搜索",

  "form.processor.createProcessor": "新建处理器",
  "form.processor.processorName": "处理器名称",
  "form.processor.image": "镜像信息",
  "form.processor.appCommand": "启动命令",
  "form.processor.appExecutionMode": "运行模式",
  "form.processor.collectType": "类型",
  "form.processor.triggerType": "处理器属性",
  "form.processor.gpuNum": "GPU数量",
  "form.processor.processorNameRule":
    "必填，名称中只允许含有英文字母、-以及数字，不能以数字开头",
  "form.processor.processorImageRule":
    "必填，填写的镜像文件信息不符合规范，不允许有中文字符",
  "form.processor.processorLengthRule":
    "填写的信息过长，请控制在 1-128 个字符以内",
  "form.processor.require": "必填",
  "form.processor.submitSuccess": "处理器提交成功",

  "form.task.selectTask": "选择数据批次",
  "form.task.selectProcessor": "选择处理器",
  "form.task.processorDetails": "处理器详情",
  "form.task.checkDetails": "查看详情",
  "table.processormanagement.templates.delete": "删除",
  "form.task.caution":
    " 一旦点击提交，平台会立刻开始执行任务，不支持取消任务的操作，请确认以上信息无误后再行提交！",
  "form.task.dataCollectionRule": "至少选择一项",
  "modal.errorDetail": "错误详情",
  "modal.detailProgress": "进度详情",
  "modal.item": "项",
  "modal.items": "项",
  "modal.searchDataCollection": "请输入数据视图名称前缀进行搜索",

  "modal.processorDetail.name": "名称",
  "modal.processorDetail.image": "镜像文件信息",
  "modal.processorDetail.storage_src_bucket": "原始桶",
  "modal.processorDetail.storage_dst_bucket": "目标桶",

  "error.dip": "回收bag预处理",
  "error.dpp": "bag数据处理",

  "progressBar.msg": "处理进度: ",
  "status.emptyErrorMsg": "请联系小助手FAFA",
  "status.procgoerror": "数据管理平台或对象存储连接错误",
  "status.apperror": "用户处理器内部错误",
  "status.errorDetails": "错误详情",
  "status.waiting": "等待中",
  "status.running": "进行中",
  "status.failed": "失败",
  "status.succeeded": "已完成",

  "jobtype.manual": "人工创建",
  "jobtype.system": "系统创建",
  "jobtype.mviz": "Mviz",

  "processorStatus.enable": "启用",
  "processorStatus.disabled": "禁用",

  "diskStatus.mounted": "不可拔盘",
  "diskStatus.inserted": "可拔盘",
  "diskStatus.unavailable": "已拔盘",

  "processorType.system": "自动",
  "processorType.manual": "手动",
  "processorType.mviz": "Mviz",

  "table.datamanagement.databatches.name": "数据集合名称",
  "table.datamanagement.databatches.collect_type": "类型",
  "table.datamanagement.databatches.databatch_type": "数据集合类型",
  "table.datamanagement.databatches.dataset_count": "数据集个数",
  "table.datamanagement.databatches.data_count": "数据个数",
  "table.datamanagement.databatches.created_at": "创建时间",
  "table.datamanagement.databatches.action": "操作",
  "table.datamanagement.data_view": "数据视图",
  "table.datamanagement.data_set": "数据集",
  "table.datamanagement.data": "数据",
  "table.datamanagement.column.name": "名称",
  "table.datamanagement.column.create_time": "创建时间",
  "table.datamanagement.column.collect_time": "采集时间",
  "table.datamanagement.column.owner": "创建者",
  "table.datamanagement.column.dataset_count": "数据集个数",
  "table.datamanagement.column.data_count": "数据个数",
  "table.datamanagement.column.gt_version": "真值版本",
  "table.datamanagement.column.size": "大小",
  "table.datamanagement.column.tag": "标签",
  "table.datamanagement.column.op": "操作",
  "table.datamanagement.op.cli": "复制命令",
  "table.datamanagement.op.edit_tag": "编辑标签",
  "table.datamanagement.op.detail": "查看详情",
  "table.datamanagement.op.mviz": "MViz播放",
  "table.datamanagement.op.download": "下载",

  "button.datamanagement.databatches.create": "创建数据集合",

  "form.datamanagement.databatches.databatch_name":
    "自定义名称，不能与已有数据集合同名",
  "form.datamanagement.databatches.databatch_type": "选择数据集合类型",
  "form.datamanagement.databatches.select_dataset": "选择数据集",
  "form.datamanagement.databatches.search_dataset": "搜索数据集",

  "table.datamanagement.datasets.name": "数据集名称",
  "table.datamanagement.datasets.collect_type": "类型",
  "table.datamanagement.datasets.dataset_type": "数据集类型",
  "table.datamanagement.datasets.data_count": "数据个数",
  "table.datamanagement.datasets.created_at": "创建时间",
  "table.datamanagement.datasets.gt_version_count": "真值版本数",
  "table.datamanagement.datasets.action": "操作",
  "table.datamanagement.modal.tag.title.prefix": "编辑",
  "table.datamanagement.modal.tag.title.subfix": "的标签",
  "table.datamanagement.modal.tag.prefix":
    "请输入标签（输入支持 A-Z a-z 0-9 - _）",
  "table.datamanagement.modal.tag.subfix": "标签修改后不可恢复，请确认后再提交",
  "table.datamanagement.modal.tag.message.suc": "更新成功",
  "table.datamanagement.modal.tag.message.failed": "更新失败",

  "table.datamanagement.search": "查询",
  "table.datamanagement.search.clear": "重置",
  "table.datamanagement.search.title": "数据查询",
  "table.datamanagement.search.name": "名称",
  "table.datamanagement.search.data_space": "数据空间",
  "table.datamanagement.search.cnname": "中文名称",
  "table.datamanagement.search.enname": "英文名称",
  "table.datamanagement.search.md5": "MD5",
  "table.datamanagement.search.carname": "车牌号",
  "table.datamanagement.search.tag": "标签",
  "table.datamanagement.search.collect_time": "采集时间",
  "table.datamanagement.search.create_time": "创建时间",
  "table.datamanagement.column.update_time": "更新时间",
  "table.datamanagement.column.sync_progress": "同步进度",
  "table.datamanagement.column.task_status": "任务状态",
  "table.datamanagement.column.resync": "重新同步",
  "table.datamanagement.column.in_the_line": "排队中",
  "table.datamanagement.column.preparing": "准备中",
  "table.datamanagement.column.cancel": "取消",
  "table.datamanagement.column.synchronizing": "同步中",
  "table.datamanagement.column.completed": "已完成",
  "table.datamanagement.column.failed": "已失败",
  "table.datamanagement.column.not_support": "不支持",
  "table.datamanagement.search.timeStart": "创建时间开始",
  "table.datamanagement.search.timeEnd": "创建时间结束",
  "table.datamanagement.search.serial": "硬盘序列号",
  "table.datamanagement.search.collect_type": "采集类型",
  "table.datamanagement.search.owner": "创建者",
  "table.datamanagement.search.rd_type": "研发数采触发类型",
  "table.datamanagement.search.rd_trigger": "研发数采触发名称",
  "table.datamanagement.search.trigger_serial": "采集设备序列号",
  "table.datamanagement.search.a4": "A4 筛选器名称",
  "table.datamanagement.search.processor": "DXP 处理器名称",
  "table.datamanagement.search.process_time": "DXP 处理时间",
  "table.datamanagement.search.time.start": "开始",
  "table.datamanagement.search.time.end": "结束",
  "table.datamanagement.search.tag.placeholder": "输入内容支持模糊匹配查询",
  "table.datamanagement.search.input.placeholder":
    "输入内容精确匹配查询，支持 * 匹配",
  "table.datamanagement.search.input.placeholder2": "输入内容精确匹配查询",
  "table.datamanagement.search.show": "展开",
  "table.datamanagement.search.hide": "收起",
  "table.datamanagement.search.scope.schema": "当前数据空间查询",
  "table.datamanagement.search.scope.global": "全局查询",

  "table.datamanagement.create.view": "创建视图",
  "table.datamanagement.create.view.name": "视图名称",
  "table.datamanagement.create.view.placeholder":
    "自定义名称，全局唯一，输入支持：A-Z a-z 0-9 - _",
  "table.datamanagement.create.view.datasetBtn": "添加数据集",
  "table.datamanagement.create.view.dataset": "输入数据集名称，每行一个",
  "table.datamanagement.create.dataset": "创建数据集",
  "table.datamanagement.create.dataset.placeholder":
    "自定义名称，全局唯一，输入支持：A-Z a-z 0-9 - _",
  "table.datamanagement.create.dataset.name": "数据集名称",
  "table.datamanagement.create.dataset.tag": '数据集标签，"," 分割',
  "table.datamanagement.create.dataset.md5": "数据ID（md5），每行一个",

  "table.datamanagement.custom_tag.add": "+ 常用标签",
  "table.datamanagement.custom_tag.title": "添加常用标签",
  "table.datamanagement.custom_tag.error.toomany":
    "常用标签数量已设置超过 15 个，设置失败",
  "table.datamanagement.custom_tag.error.auth":
    "仅数据空间管理员可设置常用标签",

  "table.datamanagement.tip.mdi.anchor": "下载 DMP命令行工具（MDI 工具）",
  "table.datamanagement.tip.mdi.tip": "MDI 工具使用文档",

  "dataDetail.desc.name": "名称",
  "dataDetail.desc.create_time": "创建时间",
  "dataDetail.desc.owner": "创建者",
  "dataDetail.desc.tag": "标签",
  "dataDetail.desc.gt": "真值版本",
  "dataDetail.desc.collect_time": "采集时间",
  "dataDetail.desc.size": "数据大小",
  "dataDetail.desc.amount": "数据个数",
  "dataDetail.desc.category": "数据空间",
  "dataDetail.desc.dataset": "数据集",
  "dataDetail.desc.dataset_amount": "数据集个数",
  "dataDetail.data.relative": "相关数据",
  "dataDetail.data.meta": "Meta信息",
  "dataDetail.data.gt": "真值信息",
  "dataDetail.download": "下载",
  "dataDetail.mviz": "MViz播放",
  "dataDetail.prev": "上一个",
  "dataDetail.next": "下一个",

  "dataDetail.view": "数据视图详情",
  "dataDetail.dataset": "数据集详情",
  "dataDetail.data": "数据详情",

  "dataDetail.tip.copy": "使用 MDI 工具下载此数据的命令已复制至剪贴板",
  Description: "描述",
  "pagination.1": "每页",
  "pagination.2": "条，共",
  "pagination.3": "条数据",
  ProcessorSet: "处理器集合",
  "lableTask.TabPane.state": "状态",
  "table.taskmanagement.processtask.systemCreate": "自动创建任务",
  "table.taskmanagement.processtask.manualCreate": "手动创建任务",
  "table.taskmanagement.processtask.mvizCreate": "Mviz 处理任务",
  "table.taskmanagement.processtask.dataset_name": "输入数据视图",
  "table.taskmanagement.processtask.create_new_task": "创建处理任务",
  "table.taskmanagement.processtask.CreatedWorkflowTask": "创建工作流任务",
  "table.taskmanagement.processtask.collect_type": "处理器类型",
  "table.taskmanagement.processtask.dst_dataset_name": "输出数据视图",
  "table.taskmanagement.processtask.processor_name": "处理器",
  "table.taskmanagement.processtask.creator": "创建者",
  "table.taskmanagement.processtask.created_at": "创建时间",
  "table.taskmanagement.processtask.updated_at": "更新时间",
  "table.taskmanagement.processtask.trigger_type": "创建类型",
  "table.taskmanagement.processtask.progress": "任务进度",
  "table.taskmanagement.processtask.status": "任务状态",
  "table.taskmanagement.processtask.action": "操作",
  "table.taskmanagement.processtask.download": "下载日志",
  "table.taskmanagement.processtask.submitSuccess": "任务创建成功!",
  "table.taskmanagement.processtask.submitCheck":
    "任务提交后无法取消或修改，是否确认提交？",
  "table.taskmanagement.processtask.submitFailed":
    "处理任务创建失败，请联系管理员",
  AutoAssignedWorkflowTask: "自动创建工作流任务",
  ManuallyCreatedWorkflowTaskask: "手动创建工作流任务",
  CreatedWorkflowTaskSearchPlaceholder: "请输入数据视图名称前缀进行搜索",
  SearchWorkflowConfig: "搜索工作流配置",
  WorkflowConfigInfo: "工作流配置信息",
  "Sub-Progress": "子任务进度",
  "Sub-Status": "子任务状态",
  SucceedToAddProcessor: "处理器添加成功",
  AddedProcessorDragToAdjustTheOrder: "已经添加处理器,请拖动调整顺序",
  ThisProcessorHasBeenAdded: "此处理器已经添加!!!",
  PleaseSelectProcessor: "请选择处理器!",
  Maximum1000Characters: "最大不超过1000字符!",
  PleaseSelectWorkflowConfiguration: "请选择工作流配置!",
  PleaseSelectDataView: "请选择数据视图!",
  AreYouSureToSubmitWorkflow: "工作流配置提交后无法取消或修改,是否确认提交?",
  AreYouSureToSubmit: "任务提交后无法取消或修改,是否确认提交?",
  TaskProgressPrompt:
    "第一个处理器名称将显示在表中，将鼠标悬停在该名称上会显示工作流配置中所有处理器的信息。",
};
