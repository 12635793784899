import menu from "./en-US/menu";
import job from "./en-US/job";
import sdk from "./en-US/sdk";
import evaTpl from "./en-US/evaTpl";
import evaBase from "./en-US/evaBase";
import visual from "./en-US/visual";
import { LangConf } from "./index";
const conf: LangConf = {
  locale: "en",
  desc: "English",
  hello: "hello",
  logout: "Logout",
  "form.msg": "Please enter the correct content",
  "form.add": "Add Type",
  "form.select_msg": "Please select / add perception type",
  "page.total": "Total {total} items",
  ...menu,
  ...job,
  ...sdk,
  ...evaTpl,
  ...evaBase,
  ...visual,
};
export default conf;
