/* eslint-disable import/no-anonymous-default-export */
export default {
  "evaBase.list.cTime": "Create Time",
  "evaBase.list.name": "Name",
  "evaBase.list.status": "Status",
  "evaBase.list.resultTpl": "Report Template Name",
  "evaBase.list.createMethod": "Create Evaluation Method",
  "evaBase.list.evaName": "Evaluation Method Name",
  "evaBase.list.image": "Evaluation Image",
  "evaBase.list.caseTpl": "Result Report",
  "evaBase.list.save": "Submit",
  "evaBase.list.next": "Next",
  "evaBase.list.deploy": "Deploy",
  "evaBase.list.deployed": "Deployed",
  "evaBase.list.notDeployed": "Not Deployed",
  "evaBase.list.checkTpl": "View Report Template",
  "evaBase.list.createTpl": "Create Template",
  "evaBase.list.bindTpl": "Add Report Template",
  "evaBase.list.online": "Online",
  "evaBase.list.offline": "Offline",
  "evaBase.list.custMethod": "Create Evaluation Method",
  "evaBase.list.resTpl": "Use existing ones / Create a new one",
  "evaBase.editor.tplDetail": "Template Detail",
  "evaBase.editor.tplEditor": "Template Editor",
  "evaBase.editor.createMethod": "Create Method",
  "evaBase.editor.deployImage": "Deploy Image",
};
