export const getLang = (): string => {
  const langInStore = window.localStorage.getItem('lang');
  const langInNav = navigator.language;
  const defaultLang = 'en-US';
  return langInStore || langInNav || defaultLang;
};

export const storeLang = (lang: string): void => {
  window.localStorage.setItem('lang', lang);
};
