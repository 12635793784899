import {
  AppstoreOutlined,
  BlockOutlined,
  DatabaseOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { lazy } from "react";

export type RouteItem = {
  name: string;
  show?: boolean; // Whether displayed in the sidebar
  icon?: any; // Required in first level navigation, not in second level navigation
  path?: string;
  component?: any;
  children?: RouteItem[];
  breadTail?: string;
};

export const milRoutes: RouteItem[] = [
  // 评测任务中心
  {
    name: "joblist",
    icon: AppstoreOutlined,
    path: "/joblist",
    show: true,
    component: lazy(() => import("../mil-pages/job/list")),
  },
  {
    name: "jobdetail",
    path: "/jobdetail",
    breadTail: "jobdetailTail",
    component: lazy(() => import("../mil-pages/job/detail")),
  },
  //评测结果报告中心
  // {
  //   name: "caselist",
  //   icon: PictureOutlined,
  //   path: "/caselist",
  //   show: true,
  //   component: lazy(() => import("../mil-pages/visual/list")),
  // },
  {
    name: "casedetail",
    path: "/casedetail",
    component: lazy(() => import("../mil-pages/visual/detail")),
    breadTail: "casedetailTail",
  },
  {
    name: "caseframe",
    path: "/caseframe",
    component: lazy(() => import("../mil-pages/visual/frame")),
    breadTail: "caseframeTail",
  },
  // eva 准备区
  {
    name: "eva",
    icon: BlockOutlined,
    children: [
      {
        name: "baselist",
        path: "/eva/baselist",
        show: true,
        component: lazy(() => import("../mil-pages/eva-base/list")),
      },
      {
        name: "sdklist",
        // icon: ExperimentOutlined,
        path: "/eva/sdklist",
        show: true,
        component: lazy(() => import("../mil-pages/sdk/list")),
      },
      {
        name: "resultlist",
        path: "/eva/resultlist",
        show: true,
        component: lazy(() => import("../mil-pages/eva-tpl/list")),
      },
      {
        name: "tpldetail",
        path: "/eva/tpldetail",
        component: lazy(() => import("../mil-pages/eva-tpl/detail")),
        breadTail: "tpldetailTail",
      },
      {
        name: "baseDetail",
        path: "/eva/basedetail",
        component: lazy(() => import("../mil-pages/eva-tpl/detail")),
        breadTail: "tpldetailTail",
      },
      {
        name: "tpleditor",
        path: "/eva/tpleditor",
        component: lazy(() => import("../mil-pages/eva-tpl/editor")),
        breadTail: "tpleditorTail",
      },
      {
        name: "tplcreator",
        path: "/eva/tplcreator",
        component: lazy(() => import("../mil-pages/eva-tpl/creator")),
        breadTail: "tpleditorTail",
      },
    ],
  },
];

export const dxpRoutes: RouteItem[] = [
  {
    name: "taskmanagement",
    icon: HomeOutlined,
    show: true,
    children: [
      // {
      //   name: "datacollection",
      //   path: "/datacollection",
      //   show: true,
      //   component: lazy(() => import("../dxp-pages/dataCollection")),
      // },
      {
        name: "ingestion_tasks",
        path: "/ingestion_tasks",
        show: true,
        component: lazy(() => import("../dxp-pages/diskTask")),
      },
      {
        name: "process_tasks",
        path: "/process_tasks",
        show: true,
        component: lazy(() => import("../dxp-pages/processTasks")),
      },
    ],
  },
  {
    name: "processormanagement",
    icon: SettingOutlined,
    show: true,
    children: [
      {
        name: "processors",
        path: "/processors",
        show: true,
        component: lazy(() => import("../dxp-pages/processors")),
      },
    ],
  },

  {
    name: "datacategory",
    show: false,
    path: "/categories/:name",
    component: lazy(() => import("../dxp-pages/dataCategory")),
  },
  {
    name: "dataview",
    show: false,
    path: "/view/:name",
    component: lazy(() => import("../dxp-pages/dataDetail/view")),
  },
  {
    name: "dataset",
    show: false,
    path: "/dataset/:name",
    component: lazy(() => import("../dxp-pages/dataDetail/dataset")),
  },
  {
    name: "datasetPreview",
    show: false,
    path: "/preview/:name/:id",
    component: lazy(() => import("../dxp-pages/dataDetail/datasetPreview")),
  },
  {
    name: "data",
    show: false,
    path: "/data/:id",
    component: lazy(() => import("../dxp-pages/dataDetail/data")),
  },
  {
    name: "datamanagement",
    icon: DatabaseOutlined,
    show: true,
    children: [
      // {
      //   name: "views",
      //   path: "/views",
      //   show: true,
      //   component: lazy(() => import("../dxp-pages/dataView")),
      // },
      // {
      //   name: "datasets",
      //   path: "/datasets",
      //   show: true,
      //   component: lazy(() => import("../dxp-pages/dataSet")),
      // },
      // {
      //   name: "data",
      //   path: "/data",
      //   show: true,
      //   component: lazy(() => import("../dxp-pages/dataCategory")),
      // },
      {
        name: "baseai_test",
        path: "/categories/baseai_test",
        show: true,
      },
      {
        name: "baseai-test-0",
        path: "/categories/baseai-test-0",
        show: true,
      },
      {
        name: "bosch-test",
        path: "/categories/bosch-test",
        show: true,
      },
      {
        name: "demo",
        path: "/categories/demo",
        show: true,
      },
      {
        name: "dxp",
        path: "/categories/dxp",
        show: true,
      },
      {
        name: "gen3_tsr_test",
        path: "/categories/gen3_tsr_test",
        show: true,
      },
      {
        name: "string",
        path: "/categories/string",
        show: true,
      },
      {
        name: "traffic_sign_ingest_fvg3",
        path: "/categories/traffic_sign_ingest_fvg3",
        show: true,
      },
      {
        name: "tsr_filter",
        path: "/categories/tsr_filter",
        show: true,
      },
      {
        name: "traffic_Sign",
        path: "/categories/traffic_Sign",
        show: true,
      },

      {
        name: "updated_type",
        path: "/categories/updated_type",
        show: true,
      },

      {
        name: "Test",
        path: "/categories/Test",
        show: true,
      },

      {
        name: "Clip4a",
        path: "/categories/Clip4a",
        show: true,
      },

      {
        name: "vehicle_filter",
        path: "/categories/vehicle_filter",
        show: true,
      },
      
      {
        name: "sfda",
        path: "/categories/sfda",
        show: true,
      },
    ],
  },
];
