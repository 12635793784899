export default {
  "job.list.createJob": "创建任务",
  "job.list.currentJob": "当前任务",
  "job.list.dataSet": "数据集名称",
  "job.list.dataSetName": "数据集名称",
  "job.list.gtName": "真值版本",
  "job.list.gt": "真值",
  "job.list.sdkArtifactory": "模型版本",
  "job.list.sdkid": "模型版本ID",
  "job.list.evaName": "评测方案",
  "job.list.evaMethod": "评测方案名称",
  "job.list.type": "类型",
  "job.list.jobType": "业务类型",
  "job.list.status": "状态",
  "job.list.process": "进度",
  "job.list.createTime": "创建时间",
  "job.list.updateTime": "更新时间",
  "job.list.result": "报告",
  "job.list.checkResult": "查看报告",
  "job.list.analyzeFrames": "帧数据可视化",
  "job.list.submit": "提交",
  "job.list.sdklist": "使用现有模型版本 / 新建模型版本",
  "job.list.baselist": "使用现有评测方案 / 新建评测方案",
  "job.list.jobId": "评测任务ID",
  "job.list.click": "绑定模板",
  "job.list.noTpl": "当前还未绑定评测报告模板，是否",
  "job.list.status.1": "运行中",
  "job.list.status.2": "已失败：任务分配失败",
  "job.list.status.3": "运行中",
  "job.list.status.4": "已失败：感知运行失败",
  "job.list.status.5": "运行中",
  "job.list.status.6": "运行中",
  "job.list.status.7": "已失败：DT运行失败",
  "job.list.status.8": "运行中",
  "job.list.status.9": "已失败：评测触发失败",
  "job.list.status.10": "运行中",
  "job.list.status.11": "已失败：评测方案运行失败",
  "job.list.status.12": "已完成",
};
